/** @format */

import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import Customer from "./Customer";
import Home from "./Home";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/khach-hang" />
          </Route>
          <Route path="/home" exact component={Home} />
          <Route path="/khach-hang/:hash" exact component={Customer} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
